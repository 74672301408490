import React from "react";

import { Button } from "semantic-ui-react";
import NavBarO from "../NavBarO";
import cloudDisconnected from "../Assets/cloudDisconnected.png";
import Spacer from "../Spacer";

import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <NavBarO />
      <div className="loader" style={{ margin: "auto", textAlign: "center" }}>
        <div>
          <center>
            <img
              src={cloudDisconnected}
              alt="error-img"
              style={{ width: "100%", maxWidth: "400px", height: "100%" }}
            ></img>

            <h3 style={{ marginBottom: "0px" }}>{t("THERE_WAS_AN_ERROR")}</h3>
            <Spacer />
            <div style={{ marginTop: "4px", color: "#606c76" }}>
              {t("ERROR_REASONS")}
            </div>

            <Spacer />
            <Button primary onClick={() => window.location.reload()}>
              {t("REFRESH")}
            </Button>
          </center>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
