import React from "react";

import { Button } from "semantic-ui-react";
import NavBar from "./NavBar";
import Spacer from "./Spacer";
import server from "./Assets/server.png";

import { useTranslation } from "react-i18next";

const ServerError = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <NavBar />
      <div className="loader" style={{ margin: "auto", textAlign: "center" }}>
        <Spacer size={75} />

        <img
          src={server}
          alt="error-img"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "100px",
            height: "100px",
          }}
        ></img>

        <Spacer size={10} />

        <h3 style={{ textAlign: "center", marginBottom: "0px" }}>
          {t("INTERNAL_SERVER_ERROR")}
        </h3>
        <Spacer size={5} />
        <p
          style={{
            textAlign: "center",
            marginTop: "6px",
            marginBottom: "0px",
            color: "#606c76",
          }}
        >
          {t("TRY_AGAIN_LATER")}
        </p>
      </div>
    </>
  );
};

export default ServerError;
