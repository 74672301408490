import React from "react";

import { Button } from "semantic-ui-react";
import NavBarO from "../NavBarO";
import notFound from "../Assets/notFound.png";

import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <NavBarO />
      <div className="loader" style={{ margin: "auto", textAlign: "center" }}>
        <img
          src={notFound}
          alt="error-img"
          style={{ width: "100%", maxWidth: "400px", height: "100%" }}
        ></img>

        <h4 style={{ textAlign: "center", marginBottom: "0px" }}>
          {t("NOT_FOUND")}
        </h4>

        <h5
          style={{
            textAlign: "center",
            marginTop: "6px",
            marginBottom: "0px",
            color: "#606c76",
          }}
        >
          {t("NOT_FOUND_MSG")}
        </h5>

        <Button
          primary
          style={{ margin: "10px" }}
          onClick={() => {
            window.history.back();
          }}
        >
          {t("GO_BACK")}
        </Button>
      </div>
    </>
  );
};

export default NotFound;
