import React from "react";

import Spacer from "./Spacer";
import NavBar from "./NavBar";
import badRequest from "./Assets/badRequest.png";

import { useTranslation } from "react-i18next";

const BadRequest = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <NavBar />
      <div className="loader" style={{ margin: "auto", textAlign: "center" }}>
        <img
          src={badRequest}
          alt="error-img"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "150px",
            height: "150px",
          }}
        ></img>

        <Spacer size={10} />

        <h3 style={{ textAlign: "center", marginBottom: "0px" }}>
          {t("BAD_REQUEST")}
        </h3>
        <Spacer size={5} />
        <p
          style={{
            textAlign: "center",
            marginTop: "6px",
            marginBottom: "0px",
            color: "#606c76",
          }}
        >
          {t("BAD_REQUEST_MSG")}
        </p>
      </div>
    </>
  );
};

export default BadRequest;
