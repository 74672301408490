import React, { useEffect, useRef, useState } from "react";
import NavBar from "./NavBar";
import Spacer from "./Spacer";
import map from "./Assets/map.png";
import { Button } from "semantic-ui-react";
import pallet from "./Assets/pallet.png";
import builder from "./Assets/build.png";
import Loader from "./Loader";
import BadRequest from "./errorPages/BadRequest";
import ErrorPage from "./errorPages/ErrorPage";
import Unauthorized from "./errorPages/Unauthorized";
import Login from "./Login";
import { useCookies } from "react-cookie";
import Central from "./Central";
import axios from "axios";

import Video from "./Video";

import clive from "./Assets/clive.jpg";

import Cropper from "react-cropper";
import profilePic from "./Assets/profilePic.png";
import password from "./Assets/password.png";
import email from "./Assets/email.png";
import beach from "./Assets/water.jpg";
import { Form, Modal } from "react-bootstrap";
import { Message } from "semantic-ui-react";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";

import { useTranslation } from "react-i18next";

const Main = () => {
  const { t, i18n } = useTranslation();
  const [cookies, setCookies, removeCookie] = useCookies("token");
  const [status, setStatus] = useState(200);

  const [cropper, setCropper] = useState();
  const cropperRef = useRef(null);
  const [userProfilePic, setUserProfilePic] = useState(beach);

  const [userName, setUserName] = useState(null);
  const [userEmail, setEmail] = useState(null);
  const [profilePic, setProfilePic] = useState(null);

  const userNameInput = useRef(null);

  useEffect(() => {
    if (!cookies.token) {
      setStatus(0);
      return;
    }

    const formData = new FormData();

    formData.append("action", "auth");
    axios({
      method: "post",
      url: Central.accountsUrl,
      data: formData,
      headers: {
        Authorization: "Bearer " + cookies.token,
      },
    })
      .then((resp) => {
        return resp.data;
      })
      .then((data) => {
        console.log(data);

        switch (data.response.code) {
          case 200:
            setTimeout(() => {
              document.getElementById("main").style.display = "block";
              document.getElementById("loader").style.display = "none";
              setUserName(data.userName);

              setUserProfilePic(data.profilePic);
              setEmail(data.email);
            }, Central.loadingBuffer);

            break;

          case 500:
            setStatus(1);
            // removeCookie('token' , {path:'/'})

            break;
          case 400:
            setStatus(400);

            break;
          case 401:
            removeCookie("token", { path: "/" });
            setStatus(401);
        }
      })
      .catch((err) => {
        setStatus(1);
        console.log(err);
      });
  }, []);

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      //   console.log(cropper.getCroppedCanvas().toDataURL())
      //  console.log(previewIcon);
      return cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.2);
    }
  };

  function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      // document.getElementById("crr").src = reader.result;

      setUserProfilePic(reader.result);

      //  console.log(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const _onMoveCropBox = () => {
    // console.log(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
    //  setPreviewIcon(cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7))
    // if(document.getElementById("app-icon-prev")){
    //   document.getElementById("app-icon-prev").src = cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7);
    //   global.appIcon64 = cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7);
    //   urltoFile(cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7), 'a.png')
    //   .then(function(file){
    //       global.appIcon = file
    //   })
    // }
  };

  const throwError = (body) => {
    setErrorBody(body);
    setShowError(true);
  };

  const updateProfile = () => {
    var profile_picture;
    var user_name_input;

    try {
      urltoFile(
        cropperRef.current.cropper
          .getCroppedCanvas()
          .toDataURL("image/jpeg", 0.7),
        "a.png"
      ).then(function (file) {
        if (!cookies.token) {
          setStatus(0);
          return;
        }

        profile_picture = file;
        user_name_input = userNameInput.current.value.trim();

        if (!user_name_input) {
          throwError(t("USER_NAME_EMPTY"));
          return;
        }

        setProfilePictureUpdateBtnLoading(true);

        const fd = new FormData();
        fd.append("userName", user_name_input);
        fd.append("profilePicture", profile_picture);
        fd.append("action", "updateProfile");

        axios({
          url: Central.accountsUrl,
          method: "post",
          data: fd,
          headers: {
            Authorization: "Bearer " + cookies.token,
          },
        })
          .then((res) => res.data)
          .then((data) => {
            console.log(data);

            switch (data.response.code) {
              case 200:
                window.location.reload();
                break;

              case 500:
                setStatus(1);
                // removeCookie('token' , {path:'/'})

                break;
              case 400:
                // setStatus(400)
                setProfilePictureUpdateBtnLoading(false);

                switch (data.response.status) {
                  case "user-name-not-set":
                    throwError(t("USER_NAME_EMPTY"));
                    break;

                  case "user-name-empty":
                    throwError(t("USER_NAME_EMPTY"));
                    break;

                  case "user-name-max-length-reached":
                    throwError(t("USER_NAME_EXCEEDED"));
                    break;

                  case "profile-picture-not-set":
                    throwError(t("PROFILE_PICTURE_NOT_SET"));
                    break;

                  case "profile-picture-empty":
                    throwError(t("INVALID_PROFILE_PICTURE_IMAGE"));
                    break;
                  case "profile-picture-invalid":
                    throwError(t("INVALID_PROFILE_PICTURE_IMAGE"));
                    break;
                  case "profile-picture-exceeding-size":
                    throwError(t("PROFILE_PICTURE_IMAGE_EXCEEDING_SIZE"));
                    break;

                  case "profile-picture-type-invalid":
                    throwError(t("ACCEPTED_IMAGE_TYPES"));
                    break;
                }

                break;
              case 401:
                removeCookie("token", { path: "/" });
                setStatus(401);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      });
    } catch (e) {
      throwError("Invalid profile picture");
    }
  };

  const changePassword = () => {
    var current_password = updateCurrentPasswordRef.current.value.trim();
    var new_password = updateNewPasswordRef.current.value.trim();
    var re_new_password = updateReNewPasswordRef.current.value.trim();

    setUpdatePasswordErrorMsg(null);

    if (!current_password) {
      setUpdatePasswordErrorMsg(t("CURRENT_PASSWORD_EMPTY"));
      return;
    }
    if (!new_password) {
      setUpdatePasswordErrorMsg(t("NEW_PASSWORD_EMPTY"));
      return;
    }
    if (!re_new_password) {
      setUpdatePasswordErrorMsg(t("RE_ENTER_NEW_PASSWORD"));
      return;
    }

    if (new_password !== re_new_password) {
      setUpdatePasswordErrorMsg(t("NEW_PASSWORDS_DO_NOT_MATCH"));
      return;
    }

    setUpdatePasswordBtnLoading(true);

    if (!cookies.token) {
      setStatus(0);
      return;
    }

    const fd = new FormData();
    fd.append("action", "updatePassword");
    fd.append("currentPassword", current_password);
    fd.append("newPassword", new_password);

    axios({
      method: "post",
      url: Central.accountsUrl,
      data: fd,
      headers: {
        Authorization: "Bearer " + cookies.token,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);

        switch (data.response.code) {
          case 200:
            removeCookie("token", { path: "/" });
            setStatus(900);
            break;

          case 401:
            removeCookie("token", { path: "/" });
            setStatus(401);

            break;

          case 500:
            setStatus(1);
            break;

          case 400:
            setUpdatePasswordBtnLoading(false);

            switch (data.response.status) {
              case "current-password-not-set":
                setUpdatePasswordErrorMsg(t("ENTER_CURRENT_PASSWORD"));
                break;
              case "current-password-empty":
                setUpdatePasswordErrorMsg(
                  t("CANNOT_LEAVE_CURRENT_PASSWORD_EMPTY")
                );
                break;
              case "new-password-not-set":
                setUpdatePasswordErrorMsg(t("NEW_PASSWORD_NOT_SET"));
                break;
              case "new-password-empty":
                setUpdatePasswordErrorMsg(t("NEW_PASSWORD_EMPTY"));
                break;

              case "current-password-max-length-reached":
                setUpdatePasswordErrorMsg(t("CURRENT_PASSWORD_EXCEEDED"));
                break;
              case "new-password-max-length-reached":
                setUpdatePasswordErrorMsg(t("NEW_PASSWORD_EXCEEDED"));
                break;
              case "invalid-password":
                setUpdatePasswordErrorMsg(t("INCORRECT_PASSWORD"));
                break;
            }

            break;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeEmail = () => {
    setChangeEmailErrorMsg(null);
    setChangeEmailSuccessMsg(null);

    var newEmail = changeEmailNewEmailInputRef.current.value.trim();
    var currentPassword = changeEmailCurrentPasswordRef.current.value.trim();

    if (!newEmail) {
      setChangeEmailErrorMsg(t("ENTER_NEW_EMAIL_ID"));
      return;
    }

    if (!currentPassword) {
      setChangeEmailErrorMsg(t("ENTER_PLENTRA_ACCOUNT_PASSWORD"));
      return;
    }

    if (!cookies.token) {
      setStatus(0);
      return;
    }

    setChangeEmailLoadingbtn(true);

    var fd = new FormData();
    fd.append("action", "sendEmailChangeToken");
    fd.append("currentPassword", currentPassword);
    fd.append("newEmail", newEmail);
    fd.append("lang", i18n.language);

    axios({
      url: Central.accountsUrl,
      method: "post",
      data: fd,
      headers: {
        Authorization: "Bearer " + cookies.token,
      },
    })
      .then((resp) => resp.data)
      .then((data) => {
        console.log(data);

        switch (data.response.code) {
          case 200:
            ///success
            setChangeEmailLoadingbtn(false);
            if (data.response.status === "please-wait") {
              const hours = Math.floor(data.duration / 3600);
              const minutes = Math.floor((data.duration % 3600) / 60);
              setChangeEmailErrorMsg(
                t("ALREADY_ENTERED_EMAIL_ID_WAIT") +
                  " " +
                  hours +
                  " " +
                  t("HOURS") +
                  minutes +
                  " " +
                  t("MINUTES_AND_TRY_AGAIN")
              );
              return;
            }
            setChangeEmailSuccessMsg(t("VERIFICATION_LINK_SENT"));

            break;

          case 401:
            removeCookie("token", { path: "/" });
            setStatus(401);

            break;

          case 500:
            setStatus(1);
            break;

          case 400:
            setChangeEmailLoadingbtn(false);
            switch (data.response.status) {
              case "invalid-password":
                setChangeEmailErrorMsg(t("INCORRECT_PASSWORD"));

                break;
              case "invalid-email":
                setChangeEmailErrorMsg(t("INVALID_EMAIL_ID"));

                break;
              case "current-password-not-set":
                setChangeEmailErrorMsg(t("ENTER_PLENTRA_ACCOUNT_PASSWORD"));
                break;

              case "current-password-empty":
                setChangeEmailErrorMsg(t("PASSWORD_EMPTY"));
                break;

              case "new-email-not-set":
                setChangeEmailErrorMsg(t("ENTER_NEW_EMAIL_ID"));
                break;

              case "new-email-empty":
                setChangeEmailErrorMsg(t("EMAIL_ID_EMPTY"));
                break;

              case "current-password-max-length-reached":
                setChangeEmailErrorMsg(t("CURRENT_PASSWORD_EXCEEDED"));
                break;

              case "email-max-length-reached":
                setChangeEmailErrorMsg(t("EMAIL_ID_EXCEEDED"));
                break;

              case "email-already-exists":
                setChangeEmailErrorMsg(
                  t("ACCOUNT_WITH_THIS_EMAIL_ID_ALREADY_EXISTS")
                );
                break;
            }

            break;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //change email refs

  const changeEmailNewEmailInputRef = useRef(null);
  const changeEmailCurrentPasswordRef = useRef(null);
  const [changeEmailErrorMsg, setChangeEmailErrorMsg] = useState(null);
  const [changeEmailLoadingBtn, setChangeEmailLoadingbtn] = useState(false);
  const [changeEmailSuccessMsg, setChangeEmailSuccessMsg] = useState(null);

  ///

  const [showEmailUpdateModal, setShowEmailUpdateModal] = useState(false);
  const [showPasswordUpdateModal, setShowPasswordChangeModal] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorBody, setErrorBody] = useState(null);

  const [profilePictureUpdateBtnLoading, setProfilePictureUpdateBtnLoading] =
    useState(false);

  const updateCurrentPasswordRef = useRef(null);
  const updateNewPasswordRef = useRef(null);
  const updateReNewPasswordRef = useRef(null);

  const [updatePasswordErrorMsg, setUpdatePasswordErrorMsg] = useState(null);

  const [updatePasswordBtnLoading, setUpdatePasswordBtnLoading] =
    useState(false);

  const [changeEmailCurrentPassword, setChangeEmailCurrentPassword] =
    useState("password");
  const [updateCurrentPasswordType, setUpdateCurrentPasswordType] =
    useState("password");
  const [updateNewPasswordType, setUpdateNewPasswordType] =
    useState("password");
  const [updateReNewPasswordType, setUpdateReNewPasswordType] =
    useState("password");

  const PasswordUpdated = () => {
    return (
      <div className="content">
        <div className="custom-card">
          <div style={{ textAlign: "center" }}>
            <lottie-player
              src="https://assets4.lottiefiles.com/packages/lf20_y2hxPc.json"
              background="transparent"
              speed="1"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100px",
                height: "100px",
              }}
              loop
              autoplay
            ></lottie-player>

            <h2 style={{ padding: "10px" }}>{t("ACCOUNT_PASSWORD_UPDATED")}</h2>
            <p></p>
            <Button secondary onClick={() => window.location.reload()}>
              {t("REFRESH")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (status === 0) {
    return <Login />;
  } else if (status === 1) {
    return <ErrorPage />;
  } else if (status === 401) {
    return <Unauthorized />;
  } else if (status === 400) {
    return <BadRequest />;
  } else if (status === 900) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />
            <PasswordUpdated />
          </div>
        </div>
      </>
    );
  } else if (status === 200) {
    return (
      <>
        <Modal show={showError} onHide={() => setShowError(false)}>
          <Modal.Header>Failed</Modal.Header>
          <Modal.Body>{errorBody}</Modal.Body>
        </Modal>

        <Modal
          show={showEmailUpdateModal}
          onHide={() => setShowEmailUpdateModal(false)}
        >
          <Modal.Header closeButton>{t("CHANGE_EMAIL_ID")}</Modal.Header>
          <Modal.Body>
            <div>
              {t("NEW_EMAIL_ID")}
              <Form.Control
                ref={changeEmailNewEmailInputRef}
                placeholder={t("ENTER_NEW_EMAIL_ID")}
              />
            </div>
            <Spacer size={5} />
            <div>
              {t("PLENTRA_ACCOUNT_PASSWORD")}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Control
                  placeholder={t("ENTER_PLENTRA_ACCOUNT_PASSWORD")}
                  ref={changeEmailCurrentPasswordRef}
                  type={changeEmailCurrentPassword}
                  style={{ width: "100%", maxWidth: "400px" }}
                />
                <Button
                  style={{ margin: "0px 5px" }}
                  onClick={(e) => {
                    if (changeEmailCurrentPassword === "password") {
                      setChangeEmailCurrentPassword("text");
                    } else {
                      setChangeEmailCurrentPassword("password");
                    }
                  }}
                >
                  {changeEmailCurrentPassword !== "password" ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiFillEye />
                  )}
                </Button>
              </div>
            </div>

            {changeEmailErrorMsg === null ? (
              <div></div>
            ) : (
              <Message error>
                <Message.Header>{t("ERROR")}</Message.Header>
                <p>{changeEmailErrorMsg}</p>
              </Message>
            )}

            {changeEmailSuccessMsg === null ? (
              <div></div>
            ) : (
              <Message positive>
                <Message.Header>{t("SUCCESS")}</Message.Header>
                <p>{changeEmailSuccessMsg}</p>
              </Message>
            )}
          </Modal.Body>
          <Modal.Footer>
            {changeEmailLoadingBtn === true ? (
              <Button positive loading>
                {t("VERIFY")}
              </Button>
            ) : (
              <Button positive onClick={() => changeEmail()}>
                {t("VERIFY")}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showPasswordUpdateModal}
          onHide={() => setShowPasswordChangeModal(false)}
        >
          <Modal.Header closeButton>
            {t("UPDATE_ACCOUNT_PASSWORD")}
          </Modal.Header>
          <Modal.Body>
            <div>
              {t("CURRENT_PASSWORD")}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Control
                  placeholder={t("ENTER_HERE")}
                  ref={updateCurrentPasswordRef}
                  type={updateCurrentPasswordType}
                  style={{ width: "100%", maxWidth: "400px" }}
                />
                <Button
                  style={{ margin: "0px 5px" }}
                  onClick={(e) => {
                    if (updateCurrentPasswordType === "password") {
                      setUpdateCurrentPasswordType("text");
                    } else {
                      setUpdateCurrentPasswordType("password");
                    }
                  }}
                >
                  {updateCurrentPasswordType !== "password" ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiFillEye />
                  )}
                </Button>
              </div>
              <Spacer size={5} />
            </div>

            <div>
              {t("NEW_PASSWORD")}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Control
                  placeholder={t("ENTER_HERE")}
                  ref={updateNewPasswordRef}
                  type={updateNewPasswordType}
                  style={{ width: "100%", maxWidth: "400px" }}
                />
                <Button
                  style={{ margin: "0px 5px" }}
                  onClick={(e) => {
                    if (updateNewPasswordType === "password") {
                      setUpdateNewPasswordType("text");
                    } else {
                      setUpdateNewPasswordType("password");
                    }
                  }}
                >
                  {updateNewPasswordType !== "password" ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiFillEye />
                  )}
                </Button>
              </div>
              <Spacer size={5} />
            </div>

            <div>
              {t("RE_ENTER_NEW_PASSWORD")}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Control
                  placeholder={t("ENTER_HERE")}
                  ref={updateReNewPasswordRef}
                  type={updateReNewPasswordType}
                  style={{ width: "100%", maxWidth: "400px" }}
                />
                <Button
                  style={{ margin: "0px 5px" }}
                  onClick={(e) => {
                    if (updateReNewPasswordType === "password") {
                      setUpdateReNewPasswordType("text");
                    } else {
                      setUpdateReNewPasswordType("password");
                    }
                  }}
                >
                  {updateReNewPasswordType !== "password" ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiFillEye />
                  )}
                </Button>
              </div>
              <Spacer size={5} />
            </div>

            {updatePasswordErrorMsg === null ? (
              <div></div>
            ) : (
              <Message error>
                <Message.Header>{t("ERROR")}</Message.Header>
                <p>{updatePasswordErrorMsg}</p>
              </Message>
            )}
          </Modal.Body>
          <Modal.Footer>
            {updatePasswordBtnLoading === true ? (
              <Button loading positive>
                {t("CHANGE")}
              </Button>
            ) : (
              <Button onClick={() => changePassword()} positive>
                {t("CHANGE")}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <div
          id="main"
          style={{ display: "none", width: "100%", height: "100%" }}
        >
          <Video video="https://assets.plentratechnologies.com/accounts-backgrounds/home_screen.mp4" />

          <NavBar />
          <div style={{ position: "absolute", width: "100%" }}>
            <Spacer size={55} />

            <div className="content">
              <div className="custom-card" style={{ textAlign: "center" }}>
                <div>
                  <h2 style={{ padding: "10px" }}>
                    {t("WELCOME_TO_PLENTRA_ACCOUNTS")}
                  </h2>

                  <Button
                    secondary
                    onClick={() => (window.location.href = "#/signup")}
                  >
                    {t("CREATE_NEW_ACCOUNT")}
                  </Button>
                </div>
              </div>

              <Spacer size={5} />

              <div className="custom-card">
                <div>
                  <h2 style={{ padding: "10px" }}>{t("MY_PROFILE")}</h2>

                  <Cropper
                    style={{
                      height: "300px",
                      maxWidth: "300px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    id="crr"
                    src={userProfilePic}
                    viewMode={1}
                    minCropBoxHeight={100}
                    minCropBoxWidth={100}
                    ref={cropperRef}
                    crop={_onMoveCropBox}
                    draggable={true}
                    checkCrossOrigin={false}
                    cropBoxResizable={false}
                    movable={false}
                    background={true}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                  />
                </div>

                <Spacer />

                <center>
                  <Button
                    primary
                    onClick={() => {
                      document.getElementById("iconInput").click();
                    }}
                  >
                    {t("SET_PROFILE_PICTURE")}
                  </Button>
                </center>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChange}
                  id="iconInput"
                />

                <Spacer />

                <div
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <font>{t("NAME")}</font>

                  <Spacer size={3} />

                  <Form.Control
                    placeholder={t("ENTER_HERE")}
                    defaultValue={userName}
                    ref={userNameInput}
                    style={{
                      width: "100%",
                      maxWidth: "600px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />

                  <Spacer size={8} />

                  {profilePictureUpdateBtnLoading === true ? (
                    <Button positive loading>
                      {" "}
                      {t("UPDATE")}
                    </Button>
                  ) : (
                    <Button positive onClick={() => updateProfile()}>
                      {" "}
                      {t("UPDATE")}
                    </Button>
                  )}
                </div>
              </div>

              <Spacer size={5} />

              <div className="custom-card">
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ padding: "10px" }}>{t("EMAIL")}</h2>
                  <img src={email} alt="email" style={{ width: "100px" }} />
                  <p>{userEmail}</p>
                  <Button
                    onClick={() => {
                      setChangeEmailSuccessMsg(null);
                      setChangeEmailErrorMsg(null);
                      setShowEmailUpdateModal(true);
                    }}
                  >
                    {t("CHANGE_EMAIL_ID")}
                  </Button>
                </div>
              </div>
              <Spacer size={5} />

              <div className="custom-card">
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ padding: "10px" }}>{t("PASSWORD")}</h2>
                  <img src={password} alt="email" style={{ width: "100px" }} />
                  <Spacer size={5} />
                  <Button
                    onClick={() => {
                      setUpdatePasswordErrorMsg(null);
                      setShowPasswordChangeModal(true);
                    }}
                  >
                    {t("CHANGE_PASSWORD")}
                  </Button>
                </div>
              </div>
            </div>

            <Spacer />
          </div>
        </div>

        <div
          id="loader"
          style={{ width: "100%", height: "100%", margin: "auto" }}
        >
          <Loader />
        </div>
      </>
    );
  }
};

export default Main;
