import React, { useEffect, useState, useRef } from "react";
import NavBar from "./NavBarO";
import Spacer from "./Spacer";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import { Button } from "semantic-ui-react";
import SignupBg from "./Assets/signup-bg-2.jpg";
import logo from "./Assets/logo.png";
import Static from "./Static";
import axios from "axios";
import BadRequest from "./BadRequest";
import ServerError from "./ServerError";

import clive from "./Assets/clive.jpg";
import play from "./Assets/play.jpg";

import Video from "./Video";
import beach from "./Assets/water.jpg";
import Cropper from "react-cropper";
import profilePic from "./Assets/profilePic.png";
import { useTranslation } from "react-i18next";
var emailId = null;

const Signup = () => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [agree, setAgree] = useState(false);
  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const reenterpassword = useRef(null);
  const profilePic = useRef(null);

  const [modalTitle, setModalTitle] = useState("title");
  const [modalBody, setModalBody] = useState("modal body");

  const [createButtonLoading, setCreateButtonLoading] = useState(false);

  const [page, setPage] = useState(0);

  const agreed = () => {
    setAgree(!agree);
  };

  const useDifferentEmail = () => {
    setPage(0);
  };

  const getCropData = () => {
    if (typeof profilePic.current !== "undefined") {
      //   console.log(cropper.getCroppedCanvas().toDataURL())
      //  console.log(previewIcon);
      return profilePic.current.getCroppedCanvas().toDataURL("image/jpeg", 0.2);
    }
  };

  function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const formSubmit = () => {
    // e.preventDefault();

    var profile_pictuire;

    urltoFile(
      profilePic.current.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg", 0.7),
      "a.png"
    ).then(function (file) {
      profile_pictuire = file;

      setCreateButtonLoading(true);
      if (!name.current.value.length) {
        setModalTitle(t("FAILED"));
        setModalBody(t("ENTER_NAME"));
        setShow(true);
        setCreateButtonLoading(false);
      } else if (!email.current.value.length) {
        setModalTitle(t("FAILED"));
        setModalBody(t("ENTER_EMAIL_ID"));
        setShow(true);
        setCreateButtonLoading(false);
      } else if (!password.current.value.length) {
        setModalTitle(t("FAILED"));
        setModalBody(t("ENTER_PASSWORD"));
        setShow(true);
        setCreateButtonLoading(false);
      } else if (!reenterpassword.current.value.length) {
        setModalTitle(t("FAILED"));
        setModalBody(t("RE_ENTER_PASSWORD"));
        setShow(true);
        setCreateButtonLoading(false);
      } else if (password.current.value.length < 8) {
        setModalTitle(t("FAILED"));
        setModalBody(t("PASSWORD_MIN_LENGTH"));
        setShow(true);
        setCreateButtonLoading(false);
      } else if (reenterpassword.current.value.length < 8) {
        setModalTitle(t("FAILED"));
        setModalBody(t("PASSWORD_MIN_LENGTH_ERROR"));
        setShow(true);
        setCreateButtonLoading(false);
      } else if (reenterpassword.current.value !== password.current.value) {
        setModalTitle(t("FAILED"));
        setModalBody(t("PASSWORDS_NOT_MATCH"));
        setShow(true);
        setCreateButtonLoading(false);
      } else {
        emailId = email.current.value;

        var formData = new FormData();
        formData.append("action", "newAccountCreate");
        formData.append("name", name.current.value);
        formData.append("email", email.current.value);
        formData.append("password", password.current.value);
        formData.append("repassword", reenterpassword.current.value);
        formData.append("profilePicture", profile_pictuire);
        formData.append("lang", i18n.language);

        axios({
          method: "post",
          url: Static.signupUrl,
          data: formData,
        })
          .then(function (response) {
            //handle success

            const res = response.data;

            console.log(res);

            setCreateButtonLoading(false);
            switch (res.response.status) {
              case "profile-picture-empty":
                setModalTitle(t("FAILED"));
                setModalBody(t("PROFILE_PICTURE_IMAGE_INVALID"));
                setShow(true);

                break;

              case "profile-picture-exceeding-size":
                setModalTitle(t("FAILED"));
                setModalBody(t("PROFILE_PICTURE_IMAGE_SIZE"));
                setShow(true);

                break;
              case "profile-picture-type-invalid":
                setModalTitle(t("FAILED"));
                setModalBody(t("ACCEPTED_IMAGE_TYPES"));
                setShow(true);

                break;

              /////

              case "name-not-set":
                setModalTitle(t("FAILED"));
                setModalBody(t("ENTER_NAME"));
                setShow(true);

                break;

              case "email-not-set":
                setModalTitle(t("FAILED"));
                setModalBody(t("ENTER_EMAIL_ID"));
                setShow(true);
                break;

              case "password-not-set":
                setModalTitle(t("FAILED"));
                setModalBody(t("ENTER_PASSWORD"));
                setShow(true);
                break;

              case "repassword-not-set":
                setModalTitle(t("FAILED"));
                setModalBody(t("RE_ENTER_PASSWORD"));
                setShow(true);

                break;

              case "invalid-name":
                setModalTitle(t("FAILED"));
                setModalBody(t("INVALID_NAME"));
                setShow(true);
                break;

              case "invalid-email":
                setModalTitle(t("FAILED"));
                setModalBody(t("INVALID_EMAIL_ID"));
                setShow(true);
                break;

              case "passwords-not-match":
                setModalTitle(t("FAILED"));
                setModalBody(t("PASSWORDS_NOT_MATCH"));
                setShow(true);
                break;

              case "name-max-length-reached":
                setModalTitle(t("FAILED"));
                setModalBody(t("NAME_EXCEEDED"));
                setShow(true);
                break;

              case "password-max-length-reached":
                setModalTitle(t("FAILED"));
                setModalBody(t("PASSWORD_EXCEEDED"));
                setShow(true);
                break;

              case "email-max-length-reached":
                setModalTitle(t("FAILED"));
                setModalBody(t("EMAIL_ID_EXCEEDED"));
                setShow(true);
                break;

              case "account-pending":
                setPage(2);
                break;

              case "operation-failed":
                setPage(500);
                break;

              case "internal-server-error":
                setPage(500);
                break;

              case "bad-request":
                setPage(500);
                break;

              case "account-already-exists":
                setPage(3);
                break;
              case "account-created-pending":
                setPage(200);
                break;
            }
          })
          .catch(function (err) {
            //handle error
            setCreateButtonLoading(false);
            console.log(err);
          });
      }
    });
  };

  if (page === 200) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${SignupBg}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />

            <PendingState />
          </div>
        </div>
      </>
    );
  } else if (page === 400) {
    return <BadRequest />;
  } else if (page === 500) {
    return <ServerError />;
  } else if (page === 1) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />

            <AlreadyExists useDifferentEmail={useDifferentEmail} />
          </div>
        </div>
      </>
    );
  } else if (page === 2) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />

            <PendingState />
          </div>
        </div>
      </>
    );
  } else if (page === 3) {
    return (
      <>
        <div
          className="image-background"
          style={{ backgroundImage: `url('${clive}')` }}
        >
          <NavBar />
          <div className="body">
            <Spacer size={60} />

            <AlreadyExists useDifferentEmail={useDifferentEmail} />
          </div>
        </div>
      </>
    );
  } else if (page === 0) {
    return (
      <>
        <div>
          <NavBar />

          <Video video="https://assets.plentratechnologies.com/atoms.mp4" />

          <div className="body" style={{ position: "absolute", width: "100%" }}>
            <Spacer size={60} />
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {t("CLOSE")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Formm
              agree={agree}
              agreed={agreed}
              formSubmit={formSubmit}
              name={name}
              email={email}
              password={password}
              reenterpassword={reenterpassword}
              profilePic={profilePic}
              createButtonLoading={createButtonLoading}
            />
          </div>
        </div>
      </>
    );
  } ///
};

const PendingState = () => {
  const { t, i18n } = useTranslation();

  const [messageShow, setMessageShow] = useState(false);
  const [messageTitle, setMessageTitle] = useState("title");
  const [messageBody, setMessageBody] = useState("body");

  const [
    resendActivationLinkButtonLoading,
    setResendActivationLinkButtonLoading,
  ] = useState(false);

  const resendLink = () => {
    setResendActivationLinkButtonLoading(true);

    if (emailId === null) {
      setMessageTitle("An error occured");
      setMessageBody("Please refresh this page");
      setMessageShow(true);
      setResendActivationLinkButtonLoading(false);
    } else {
      var formData = new FormData();
      formData.append("action", "resendActivationLink");
      formData.append("email", emailId);
      formData.append("lang", i18n.language);
      axios({
        method: "post",
        url: Static.resendActivationLink,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((resp) => {
          const data = resp.data;

          setResendActivationLinkButtonLoading(false);

          console.log(data);

          switch (data.response.status) {
            case "success":
              setMessageTitle(t("VERIFICATION"));
              setMessageBody(t("VERIFICATION_LINK"));
              setMessageShow(true);
              break;

            case "please-wait":
              var hours = Math.floor(data.duration / 3600);
              var minutes = Math.floor((data.duration % 3600) / 60);

              setMessageTitle(t("TOO_MANY_TRIES"));
              setMessageBody(
                `${t("WE_HAVE_SENT_ENOUGH_VERIFICATION_LINKS")} ${hours} ${t(
                  "HOURS"
                )} and ${minutes} ${t("MINUTES")}`
              );
              setMessageShow(true);
              break;

            case "email-not-set":
              setMessageTitle(t("FAILED"));
              setMessageBody(t("REFRESH_PAGE"));
              setMessageShow(true);
              break;

            case "invalid-email":
              setMessageTitle(t("FAILED"));
              setMessageBody(t("INVALID_EMAIL_ID"));
              setMessageShow(true);
              break;

            case "operation-failed":
              setMessageTitle(t("FAILED"));
              setMessageBody(t("SERVER_ERROR_TRY_LATER"));
              setMessageShow(true);
              break;

            case "bad-request":
              setMessageTitle("Bad Request");
              setMessageBody(t("BAD_REQUEST_MSG"));
              setMessageShow(true);
              break;

            case "internal-server-error":
              setMessageTitle(t("FAILED"));
              setMessageBody(t("SERVER_ERROR_TRY_LATER"));
              setMessageShow(true);
              break;
          }
        })
        .catch((err) => {
          setResendActivationLinkButtonLoading(false);

          console.log(err);
        });
    }
  };

  return (
    <>
      <Modal show={messageShow} onHide={setMessageShow} centered>
        <Modal.Header closeButton>
          <Modal.Title>{messageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{messageBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMessageShow(false)}>
            {t("CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="content">
        <div className="custom-card">
          <div style={{ textAlign: "center" }}>
            <lottie-player
              src="https://assets2.lottiefiles.com/private_files/lf30_qhmjozme.json"
              background="transparent"
              speed="1"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100px",
                height: "100px",
              }}
              loop
              autoplay
            ></lottie-player>

            <h2 style={{ padding: "10px" }}>
              {t("ACCOUNT_PENDING_VERIFICATION")}
            </h2>
            <p>{t("CLICK_LINK_TO_ACTIVATE")}</p>
            <Spacer size={10} />
            {resendActivationLinkButtonLoading ? (
              <Button loading>{t("RESEND_ACTIVATION_LINK")}</Button>
            ) : (
              <Button onClick={() => resendLink()}>
                {t("RESEND_ACTIVATION_LINK")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const AlreadyExists = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="content">
        <div className="custom-card">
          <div style={{ textAlign: "center" }}>
            <lottie-player
              src="https://assets6.lottiefiles.com/private_files/lf30_pw5a608o.json"
              background="transparent"
              speed="1"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100px",
                height: "100px",
              }}
              loop
              autoplay
            ></lottie-player>

            <h2 style={{ padding: "10px" }}>{t("ACCOUNT_ALREADY_EXISTS")}</h2>

            <Button onClick={() => props.useDifferentEmail()}>
              {t("USE_DIFFERENT_EMAIL")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const Formm = (props) => {
  const { t, i18n } = useTranslation();
  const [cropper, setCropper] = useState();
  const cropperRef = useRef(null);
  const [userProfilePic, setUserProfilePic] = useState(profilePic);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      // document.getElementById("crr").src = reader.result;

      setUserProfilePic(reader.result);

      //  console.log(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const _onMoveCropBox = () => {
    // console.log(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
    //  setPreviewIcon(cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7))
    // if(document.getElementById("app-icon-prev")){
    //   document.getElementById("app-icon-prev").src = cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7);
    //   global.appIcon64 = cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7);
    //   urltoFile(cropperRef.current.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7), 'a.png')
    //   .then(function(file){
    //       global.appIcon = file
    //   })
    // }
  };

  return (
    <>
      <div className="content">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div>
                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    backgroundColor: "#f5f6f7",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    borderRadius: "6px",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                  }}
                >
                  <img src={logo} alt="logo" style={{ height: "55px" }} />

                  <Spacer />

                  {/* <div onSubmit={props.formSubmit}> */}
                  <div>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>
                        {t("NAME")}
                        <sup style={{ color: "red" }}> *</sup>
                      </Form.Label>
                      <Form.Control
                        ref={props.name}
                        type="name"
                        placeholder={t("ENTER_HERE")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>
                        {t("EMAIL_ID")} <sup style={{ color: "red" }}> *</sup>
                      </Form.Label>
                      <Form.Control
                        ref={props.email}
                        type="email"
                        placeholder={t("ENTER_HERE")}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>
                        {t("PASSWORD")}
                        <sup style={{ color: "red" }}> *</sup>
                      </Form.Label>
                      <Form.Control
                        ref={props.password}
                        type="password"
                        placeholder={t("ENTER_HERE")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="reenter-password">
                      <Form.Label>
                        {t("RE_ENTER_PASSWORD")}
                        <sup style={{ color: "red" }}> *</sup>
                      </Form.Label>
                      <Form.Control
                        ref={props.reenterpassword}
                        type="password"
                        placeholder={t("ENTER_HERE")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="image">
                      <Form.Label>{t("PROFILE_PICTURE")} </Form.Label>

                      <Cropper
                        style={{
                          height: "300px",
                          maxWidth: "300px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        id="crr"
                        checkCrossOrigin={false}
                        src={userProfilePic}
                        viewMode={1}
                        minCropBoxHeight={100}
                        minCropBoxWidth={100}
                        ref={props.profilePic}
                        crop={_onMoveCropBox}
                        draggable={true}
                        cropBoxResizable={false}
                        movable={false}
                        background={true}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />

                      <Spacer />
                      <center>
                        <Button
                          primary
                          onClick={(e) => {
                            e.preventDefault();
                            document.getElementById("iconInput").click();
                          }}
                        >
                          {t("SET_PROFILE_PICTURE")}
                        </Button>
                      </center>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={onChange}
                        id="iconInput"
                      />

                      <Spacer />

                      {/* <Form.Control ref={props.profilePic} type="file"  name="image"  /> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onClick={() => props.agreed()}
                        label={t("I_AGREE_TOC")}
                        defaultChecked={props.agree}
                      />
                    </Form.Group>

                    {props.agree ? (
                      <>
                        {" "}
                        {props.createButtonLoading ? (
                          <Button loading primary>
                            {" "}
                            {t("LOADING")}
                          </Button>
                        ) : (
                          <Button primary onClick={() => props.formSubmit()}>
                            {t("CREATE")}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button primary disabled>
                        {t("CREATE")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Spacer />
    </>
  );
};
export default Signup;
